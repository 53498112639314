<template>
    <svg width="300" height="300" fill="none"
        viewBox="0 0 300 300">
        <defs>
            <pattern id="dots" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-white" fill="currentColor" />
            </pattern>
        </defs>
        <rect width="300" height="300" fill="url(#dots)" />
    </svg>
</template>